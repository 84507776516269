import React from 'react'
import { Routes, Route } from 'react-router'
import Mapping from './Pages/Mapping'
function App() {
  return (
    <Routes>
      <Route path='/:Id' Component={Mapping} />    </Routes>
  )
}

export default App