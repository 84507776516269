import React, { useEffect, useState } from "react";
import Image from "../image.jpg";
import "../App.css";
import ImageMapper from "react-img-mapper";
import Modal from "../Component/Popup";
import Loader from "../Reusable/Loader/logoLoad";
import { useParams } from "react-router-dom";
const getCenterCoords = (areas) =>
    areas.map((area) => {
        const xCoords = area.coords.filter((_, i) => i % 2 === 0);
        const yCoords = area.coords.filter((_, i) => i % 2 !== 0);
        const xCenter = (Math.min(...xCoords) + Math.max(...xCoords)) / 2;
        const yCenter = (Math.min(...yCoords) + Math.max(...yCoords)) / 2;
        return { ...area, center: { x: xCenter, y: yCenter } };
    });

function App() {
    const [loading, setLoading] = useState(true);
    const { Id } = useParams();
    const [extendedAreas, setExtendArea] = useState();

    const [layoutImage, setLayoutImage] = useState("");
    const [ModalData, setModalData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
    const [data, setdata] = useState([]);
    async function fetchData() {
        setLoading(true);
        //                `https://api.adurigroup.com/api/v1/associate/getVenture/${Id}`

        try {
            const response = await fetch(
                `http://13.233.190.78:3002/api/v1/associate/getVenture/${Id}`
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const res = await response.json();
            if (res.Status == 1) {
                // console.log(data)
                setdata(res.plots);
                let spanData = res?.plots?.map((item) => ({
                    plot_number: item.plot_number,
                    coords: item.plot_coordinates.split(",").map(Number),
                }));
                setExtendArea(getCenterCoords(spanData));
                setLayoutImage(res?.layoutImage);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
            // You can handle the error here, such as displaying an error message to the user.
            // For example:
            // displayErrorMessage(error.message);
            return null; // Or you can return a default value or do nothing depending on your needs.
        }
    }

    useEffect(() => {
        if (Id) {
            fetchData();
        }
    }, []);
    const URL = Image;
    const ColorCodes = (status) => {
        switch (status) {
            case 1:
                return {
                    Dark: "rgba(0, 168, 76, 1)",
                    Light: "rgba(24, 139, 24, 0.5)",
                    Status: "Available",
                };
            case 2:
                return {
                    Dark: "rgba(25, 153, 161, 1)",
                    Light: "rgba(25, 153, 161, 0.5)",
                    Status: "Registered",
                };
            case 3:
                return {
                    Dark: "rgba(239, 72, 54, 1)",
                    Light: "rgba(239, 72, 54, 0.5)",
                    Status: "Allotted",
                };
            case 4:
                return {
                    Dark: "rgba(255, 140, 0, 1)",
                    Light: "rgba(255, 215, 0, 0.6)",
                    Status: "Reserved",
                };
            case 5:
                return {
                    Dark: "rgba(108, 71, 71, 1)",
                    Light: "rgba(217, 175, 175, 0.6)",
                    Status: "Blocked",
                };
            case 7:
                return {
                    Dark: "rgb(193, 154, 107)",
                    Light: "rgb(193, 154, 107)",
                    Status: "Mortgage",
                };
            default:
                return {
                    Dark: "rgba(0, 0, 0, 1)",
                    Light: "rgba(255, 255, 255, 0.6)",
                    Status: "Unknown",
                };
        }
    };
    const calculateCenter = (coords) => {
        const xCoords = coords.filter((_, i) => i % 2 === 0);
        const yCoords = coords.filter((_, i) => i % 2 !== 0);
        const xCenter = (Math.min(...xCoords) + Math.max(...xCoords)) / 2;
        const yCenter = (Math.min(...yCoords) + Math.max(...yCoords)) / 2;
        return { x: xCenter, y: yCenter };
    };
    const MAP = {
        name: "my-map",
        // GET JSON FROM BELOW URL AS AN EXAMPLE
        areas: data.map((item, index) => ({
            id: index,
            title: "Hardwood",
            shape: "poly",
            name: "1",
            preFillColor:
                item?.isMortgage == 1
                    ? "rgb(193, 154, 107)"
                    : ColorCodes(item.plot_status).Dark,
            strokeColor: "black",
            fillColor:
                item?.isMortgage == 1
                    ? "rgb(193, 154, 107)"
                    : ColorCodes(item.plot_status).Dark,
            coords: item.plot_coordinates.split(",").map(Number),
            plot_status: item.plot_status,
            sector_type: item.sector_type,
            facing_type: item.facing_type,
            plot_number: item.plot_number,
            dimensions: item.dimensions,
            plot_number: item?.plot_number,
            facing_type: item?.facing_type,
            plot_area_sqy: item?.plot_area_sqy,
            sector_type: item?.sector_type,
        })),
    };

    const handleMouseEnter = (area, n, event) => {
        console.log(event.target);

        setModalData(area);
        const { coords } = area;
        const [x, y] = coords;

        // Get the position of the image container relative to the viewport
        const imageContainer = document.querySelector(".image-container");
        const rect = imageContainer.getBoundingClientRect();
        //
        // Calculate the position of the modal relative to the viewport
        const modalX = event.pageX - rect.left;
        const modalY = event.pageY - rect.top;

        // Set the modal position
        setModalPosition({ x: x, y: y });
        setShowModal(true);
    };

    const handleMouseLeave = (e) => {};

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div
                    className="image-container"
                    style={{ position: "relative" }}>
                    <ImageMapper
                        src={layoutImage}
                        map={MAP}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    />

                    {MAP.areas.map((area, index) => {
                        const center = calculateCenter(area.coords);
                        return (
                            <div
                                key={index}
                                style={{
                                    position: "absolute",
                                    left: `${center.x}px`,
                                    top: `${center.y}px`,
                                    transform: "translate(-50%, -50%)", // Center horizontally and vertically
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    pointerEvents: "none",
                                }}>
                                {index + 1}
                            </div>
                        );
                    })}

                    {extendedAreas.map((area) => (
                        <span
                            key={area.id}
                            className="tooltip"
                            style={{
                                position: "absolute",
                                top: `${area.center.y}px`,
                                left: `${area.center.x}px`,
                                transform: "translate(-50%, -50%)", // Center the tooltip
                                zIndex: 1000,
                            }}>
                            {area.plot_number}
                        </span>
                    ))}

                    {showModal && (
                        <Modal
                            ModalData={ModalData}
                            x={modalPosition.x}
                            y={modalPosition.y}
                            onClose={() => setShowModal(false)}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default App;
