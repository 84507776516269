const Modal = ({ x, y, onClose, ModalData }) => {
    console.log(x, y);
    const ColorCodes = (status) => {
        switch (status) {
            case 1:
                return {
                    Dark: "rgba(24, 139, 24, 1)",
                    Light: "rgba(24, 139, 24, 0.5)",
                    Status: "Available",
                };
            case 2:
                return {
                    Dark: "rgba(25, 153, 161, 1)",
                    Light: "rgba(25, 153, 161, 0.5)",
                    Status: "Registered",
                };
            case 3:
                return {
                    Dark: "rgba(239, 72, 54, 1)",
                    Light: "rgba(239, 72, 54, 0.5)",
                    Status: "Allotted",
                };
            case 4:
                return {
                    Dark: "rgba(255, 140, 0, 0.6)",
                    Light: "rgba(255, 215, 0, 0.6)",
                    Status: "Reserved",
                };
            case 5:
                return {
                    Dark: "rgba(108, 71, 71, 0.6)",
                    Light: "rgba(217, 175, 175, 0.6)",
                    Status: "Blocked",
                };
            default:
                return {
                    Dark: "rgba(0, 0, 0, 0.6)",
                    Light: "rgba(255, 255, 255, 0.6)",
                    Status: "Unknown",
                };
        }
    };
    return (
        <div
            className="modal"
            style={{ top: y, left: x }}
            onMouseEnter={() => {}}
            onMouseLeave={onClose}>
            <table className="data-wrapper">
                <tbody>
                    <tr>
                        <td>
                            <b>Plot No </b>
                        </td>
                        <td>{ModalData?.plot_number}</td>
                        <td>
                            <b>Sector</b>
                        </td>
                        <td>{ModalData.sector_type}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>area </b>
                        </td>
                        <td>{ModalData.plot_area_sqy} </td>
                        <td>
                            <b>Facing </b>
                        </td>
                        <td>{ModalData.facing_type}</td>
                    </tr>{" "}
                    <tr>
                        <td>
                            <b>{ModalData.dimensions[0].name} :</b>
                        </td>
                        <td>{ModalData.dimensions[0].LengthSide}</td>
                        <td>
                            <b>{ModalData.dimensions[1].name} </b>
                        </td>
                        <td>{ModalData.dimensions[1].LengthSide}</td>
                    </tr>
                    <tr>
                        {" "}
                        <td>
                            <b>{ModalData.dimensions[2].name} :</b>
                        </td>
                        <td>{ModalData.dimensions[2].LengthSide}</td>
                        <td>
                            <b>{ModalData.dimensions[3].name}</b>
                        </td>
                        <td>{ModalData.dimensions[3].LengthSide}</td>
                    </tr>
                </tbody>
            </table>
            <div
                colSpan={2}
                className={"status"}
                style={{
                    color: "white",
                    backgroundColor: ColorCodes(ModalData?.plot_status).Dark,
                    margin: "1rem",
                }}>
                <span>{ColorCodes(ModalData?.plot_status).Status}</span>
            </div>
            {/* <button onClick={onClose}>Close</button> */}
        </div>
    );
};

export default Modal;
