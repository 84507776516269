import React from "react";
import Logo from "../../Assets/logo.png";
import Styles from "./Styles.module.css";
const index = () => {
    return (
        <div className={Styles["Container"]}>
            <div>
                <img src={Logo} alt="" />
            </div>
        </div>
    );
};

export default index;
